import * as React from "react"
import { navigate } from '@reach/router'
import { Layout } from '@components'
import { Helmet } from 'react-helmet'

const NotFoundPage = () => {
  React.useEffect(() => {
    navigate('/')
  }, [])
  return (
    <Layout>
      <Helmet>
        <title>Bawi Agua Fresca: Not Found</title>
      </Helmet>
      <h1>not found</h1>
    </Layout>
  )
}

export default NotFoundPage
